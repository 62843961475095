import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

import { MatTableDataSource } from '@angular/material/table';
import { SimilarStructure, Structure} from '../entry/structure-data.models';
import { MatPaginator } from '@angular/material/paginator';
import { ConfigService } from '../config.service';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../common.service';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { EntryStatusComponent } from '../entry-status/entry-status.component';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { DomSanitizer } from "@angular/platform-browser";
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SelectionModel } from '@angular/cdk/collections';
import { HttpResponse } from '@angular/common/http';
import { PALETTE } from '../molstar-alpha-missense';
import { CheckboxService } from './checkbox.service';
import { Subscription } from 'rxjs';
import * as XLSX from 'xlsx';
declare var PDBeMolstarPlugin: any;

@Component({
  selector: 'app-structure-table',
  templateUrl: './structure-table.component.html',
  styleUrls: ['./structure-table.component.css'],

  changeDetection: ChangeDetectionStrategy.OnPush
})

export class StructureTableComponent implements OnInit, AfterViewInit{
  @Input() accession: boolean;
  @Input() afdb_accession: boolean;
  
  hasStructureData: number;

  totalStructure: number;
  totalAFDBStructure: number;
  checked = false;
  disabled = false;
  superposeData = [];

  hasPDBData: number;
  displayedColumnsStructureWithExpand: string[] = ['expand', 'afdbAccessions', 'descriptions', 'speciesNames','sequenceLength', 'evalue', 'sequenceIdentity', 'resolution', 'alighin3D'];
  pdbStructureDataSource = new MatTableDataSource<SimilarStructure>();

  hasAFDBData: number;
  displayedColumnsAFDBStructure: string[] = ['expand', 'afdbAccessions', 'speciesNames', 'sequenceLength', 'evalue', 'sequenceIdentity', 'averagePlddt', 'alighin3D'];
  afdbStructureDataSource = new MatTableDataSource<SimilarStructure>();

  isStructureCach: boolean = true;
  isStructureRunning: boolean = false;
  isStrcutureData: boolean = false;
  isStructureFail: boolean = false;

  isAFDBFoldseekCach: boolean = true;
  isPDBFoldseekCach: boolean = true;

  subTimeout:any;
  isColorby: boolean= true;
  apiData = {};
  targetColors = {};
  checkboxState: { [key: string]: boolean } = {};
  disabledState: { [key: string]: boolean } = {};
  private subscription: Subscription;
  private subscription2: Subscription;

  structure: Structure;
  pdbeMolstar: any;
  molLoadComplete:boolean = false;
  viewerInstance: any = undefined;
  apiData2 = [];

  records="10";
  start="0";
  startPDB = "0";
  startAFDB = "0";

  errorPDB: string;
  errorAFDB: string;

  recordsPDB = "10";
  recordsAFDB = "10";

  currentPDBPage = 0;
  currentAFDBPage = 0;

  sort_direction_PDB='asc'; 
  sort_column_PDB='evalue'; 

  sort_direction_AFDB='asc'; 
  sort_column_AFDB='evalue';

  species_name_filter_PDB="";
  species_name_filter_AFDB="";

  filteritemPDB = "";
  filteritemAFDB = "";

  pdbZeroResultsMsg = "";
  afdbZeroResultsMsg = "";

  isPDBDownloadEnabled: boolean = true;
  isAFDBDownloadEnabled: boolean = true;

  isPDBLoading = false;
  isAFDBLoading = false;

  constructor(
    private sanitizer:DomSanitizer,
    private route: ActivatedRoute,
    private commonService: CommonService,
    public gaService: GoogleAnalyticsService,
    private configService: ConfigService,
    private changeDetectorRef: ChangeDetectorRef,
    private checkboxService: CheckboxService,
    private el: ElementRef) {
      this.hasStructureData = -1;
      this.hasPDBData = -1;
      this.hasAFDBData = -1;
  }

  private assignedColor: {[targetId: string]: string} = {};
  private nextColorIndex = 0;

  isDisabled = false;

  @ViewChild('paginator3',{ static: true }) paginator3!: MatPaginator;
  @ViewChild('paginator4',{ static: true }) paginator4!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('tabGroup') tabGroup: MatTabGroup;
 
  expandedElement
  expandRow(element) {
    this.expandedElement = this.expandedElement === element ? null : element
  }

  expandedElements = new Set();

  toggleRowPDB(element) {
    this.gaService.eventEmitter(
      'foldseek_expand_seq', 'FS_similar_structures', 'click', 'foldseek_expand_sequence', 'Clicks on the expand row triangle to see sequence alignment on the Foldseek results table'
    );
    if (this.expandedElements.has(element)) {
      this.expandedElements.delete(element);
    } else {
      this.expandedElements.add(element);
    }
  }

  isExpanded(element): boolean {
    return this.expandedElements.has(element);
  }

  isExpansionDetailRow = (i: number, row) => this.isExpanded(row);

  expandedElementAFDB
  expandRowAFDB(element) {
    this.expandedElementAFDB = this.expandedElementAFDB === element ? null : element
  }

  expandedElementsAFDB = new Set();

  toggleRowAFDB(element ) {
    if (this.expandedElementsAFDB.has(element)) {
      this.expandedElementsAFDB.delete(element);
    } else {
      this.expandedElementsAFDB.add(element);
    }
  }

  isExpandedAFDB(element): boolean {
    return this.expandedElementsAFDB.has(element);
  }

  isExpansionDetailRowAFDB = (i: number, row) => this.isExpandedAFDB(row);

  ngOnInit(): void {
    this.subscription = this.checkboxService.checkboxState$.subscribe(state => {
      this.checkboxState = state;
      this.changeDetectorRef.detectChanges(); // Trigger change detection manually
    });

    this.checkboxService.disabledState$.subscribe(state => {
      this.disabledState = state;
    });
    this.checkAndGetStructureData(this.recordsPDB, this.startPDB, this.sort_direction_PDB, this.sort_column_PDB, this.species_name_filter_PDB);
    this.checkAndGetAFDBData(this.recordsAFDB, this.startAFDB, this.sort_direction_AFDB, this.sort_column_AFDB, this.species_name_filter_AFDB);
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
  }
  exportSuperpose(){
    PDBeMolstarPlugin.extensions.Foldseek.exportModels(this.viewerInstance);
    this.gaService.eventEmitter('foldseek_download', 'FS_similar_structures', 'click', 'foldseek_download_structures', 'Clicks on the "Download aligned coordinates (mmCIF)" button on the Foldseek similar structure table');
  }

  handlePDBError(species_name_filter, errormsg=""){
    this.hasPDBData = -1;
    this.totalStructure = 0;
    this.pdbStructureDataSource = null;

    if(species_name_filter != ""){
      this.isPDBDownloadEnabled = false;
      this.hasPDBData = 1;
      this.errorPDB = "No species match your search";
      this.pdbZeroResultsMsg="";
    }else{
      if(errormsg.startsWith("The search was completed successfully, but no matching results were found")){
        this.pdbZeroResultsMsg = "No matching experimentally determined structures were found in the PDB.";
      }else{
        this.pdbZeroResultsMsg="";
        this.errorPDB = "No species match your search";
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  handleAFDBError(species_name_filter, errormsg){
    this.hasAFDBData = -1;
    this.totalAFDBStructure = 0;
    this.afdbStructureDataSource = null;
   
    if(species_name_filter != ""){
      this.isAFDBDownloadEnabled = false;
      // this.changeDetectorRef.detectChanges();
      this.hasAFDBData = 1;
      this.errorAFDB = "No species match your search";
      if(this.totalStructure === 0){
        setTimeout(() => {
          this.switchtoTab("afdb");
        }, 0);
      }
      this.afdbZeroResultsMsg="";
      
    }else{
      if(errormsg.startsWith("The search was completed successfully, but no matching results were found")){
        this.afdbZeroResultsMsg = "No matching predicted structures were found in the AlphaFold Database.";
      }else{
        this.afdbZeroResultsMsg="";
        this.errorAFDB = "No species match your search";
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  switchtoTab(flag) {
    if (this.tabGroup && flag === "afdb" ) {
      this.tabGroup.selectedIndex = 1; 
    } 
    if (this.tabGroup && flag === "pdb" ) {
      this.tabGroup.selectedIndex = 0; 
    }
  }
  handlePageEvent(e, flag){
    const start = ((e.pageIndex * e.pageSize)).toString();
    this.records = e.pageSize.toString();
    this.start = start;

    this.gaService.eventEmitter(
      'foldseek_table_action', 'FS_similar_structures', 'click', 'foldseek_paginate', 'Clicks on the paginations in the Foldseek results table'
    );

    if(flag === "pdb"){
      this.startPDB = start;
      this.recordsPDB = e.pageSize.toString();
      this.currentPDBPage = e.pageIndex;
      let species_name_filter_PDB = "";
      if(this.filteritemPDB !== ""){
        species_name_filter_PDB = this.filteritemPDB;
      }
      this.checkAndGetStructureData(this.recordsPDB, start, this.sort_direction_PDB, this.sort_column_PDB, species_name_filter_PDB);
    }
    if(flag === "afdb"){
      this.startAFDB = start;
      this.recordsAFDB = e.pageSize.toString();
      this.currentAFDBPage = e.pageIndex;
      let species_name_filter_AFDB = "";
      if(this.filteritemAFDB !== ""){
        species_name_filter_AFDB = this.filteritemAFDB;
      }
      this.checkAndGetAFDBData(this.recordsAFDB, start, this.sort_direction_AFDB, this.sort_column_AFDB, species_name_filter_AFDB);
    }
  }

  async initViewer(){
    if (this.isStructureRunning || this.superposeData.length > 0) return;
    this.changeDetectorRef.detectChanges();
    
    this.viewerInstance = new PDBeMolstarPlugin();
    const viewerContainer = document.getElementById('superpose-viewer');
    if (!viewerContainer) return;
    
    const options = {
      customData: {
          url: `https://alphafold.ebi.ac.uk/files/AF-${this.accession}-F1-model_v4.cif`,
          format: 'cif',
          binary: false,
      },
      bgColor: { r: 255, g: 255, b: 255 },
      alphafoldView: true,
      sequencePanel: false,
      hideControls: true,
      hideCanvasControls: ['selection', 'animation', 'controlToggle', 'controlInfo', 'trajectory' ],
      visualStyle: {
          polymer: { type: 'putty', size: 'uniform' },
          het: 'ball-and-stick',
          nonStandard: 'ball-and-stick',
          carbs: 'carbohydrate',
      },
      hideStructure: ['water'],
      // expanded: true,
    };
    this.changeDetectorRef.detectChanges(); 
    // Call render method to display the 3D view
    this.viewerInstance.render(viewerContainer, options);
  }
 
  private getColor(targetId: string): string{
    if (this.assignedColor[targetId]) return this.assignedColor[targetId];
    const color = PALETTE[this.nextColorIndex];
    this.nextColorIndex = (this.nextColorIndex+1) % PALETTE.length;
    this.assignedColor[targetId] = color;
    return color;
  }

  async loadTarget(id) {
   
    try{
      this.checkboxService.setCheckboxDisabledState(id, true);
      this.changeDetectorRef.detectChanges();
      const result = await PDBeMolstarPlugin.extensions.Foldseek.loadFoldseekSuperposition(this.viewerInstance, id, this.apiData[id], this.getColor(id));
      return result;
    } 
    finally{
      this.checkboxService.setCheckboxDisabledState(id, false);
      this.changeDetectorRef.detectChanges();
    }
    
  }
  
  setsuperpose_color(){
    if(this.isColorby){
      this.viewerInstance.visual.clearSelection('main');
    }else{
      this.viewerInstance.visual.select({ data: [{ color: '#A9ABAA' }], structureId: 'main' });
    }
  }
  toggleSupeposeVisibility(event, entry: any) {
    entry.isShow = !entry.isShow;
    const structId = entry.accession;
    if (!entry.isShow) {
        this.viewerInstance.visual.structureVisibility(structId, false);
        this.gaService.eventEmitter('foldseek_align', 'FS_similar_structures', 'click', 'foldseek_align_hide', 'Click on the eye icon for aligned structure');
    } else {
        this.viewerInstance.visual.structureVisibility(structId, true);
        this.gaService.eventEmitter('foldseek_align', 'FS_similar_structures', 'click', 'foldseek_align_pLDDT', 'Click on checkbox for pLDDT colouring');
    }
  }
  removeSuperpose(item) {
    const accession = item.accession;
    this.superposeData = this.superposeData.filter(entry => entry.accession !== accession);
    this.checkboxService.setCheckboxDisabledState(accession, true);
    this.viewerInstance.deleteStructure(item.accession).then(() => {
      this.checkboxService.setCheckboxDisabledState(accession, false);
    });

    this.checkboxService.setCheckboxState(accession, false);
    const checkbox = document.getElementById(`${accession}`) as HTMLInputElement; 
    if (checkbox) {
      checkbox.checked = false;
    }
  }
  trackBySuperposedItem(index: number, item: any): any {
    return item.id || index; 
  }

  async superposeSliderToggle(e, accession){
    const checked = e.target.checked;
    const id = accession;
    let rmsdData;
    
    if (checked) {
      this.checkboxService.setCheckboxState(id, checked);
      await this.loadTarget(id).then((result) => {
        rmsdData = result;
      });
      const numrmsd = parseFloat(rmsdData.rmsd).toFixed(2); // Convert to a number
    
      this.superposeData.push({accession: id, targetToShow: this.apiData[id].targetToShow, refLinkId: this.apiData[id].refLinkId, rmsd:`RMSD ${numrmsd}`,  desc:this.apiData[id].desc, color:this.getColor(id), isShow: true});
      this.changeDetectorRef.detectChanges();

      this.gaService.eventEmitter('foldseek_align', 'FS_similar_structures', 'click', 'foldseek_align_3D', 'Click on the align 3D structures');
    } else {
      this.checkboxService.setCheckboxDisabledState(id, true);
      await this.viewerInstance.deleteStructure(id).then(() => {
        this.checkboxService.setCheckboxDisabledState(id, false);
        this.changeDetectorRef.detectChanges();
      });

      this.checkboxService.setCheckboxState(id, checked);
      this.superposeData = this.superposeData.filter(entry => entry.accession !== id);

      this.changeDetectorRef.detectChanges();
    }
  }

  removeAllSuperposition() {
    for (const entry of this.superposeData) {
      this.checkboxService.setCheckboxState(entry.accession, false);
      this.viewerInstance.deleteStructure(entry.accession).then(() => {});
    }
    this.superposeData = [];
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  sortTable(sortState: Sort, flag) {
    let tag = "";
    let gtagmsg = "";
    if(sortState.direction == "asc" && sortState.active == "evalue") {
      tag = "foldseek_evalue_sort_asc";
      gtagmsg = "Clicks on the sort be ascending order for E-value in the Foldseek results table";
    }
    if(sortState.direction == "desc" && sortState.active == "evalue") {
      tag = "foldseek_evalue_sort_desc";
      gtagmsg = "Clicks on the sort be descending order for E-value in the Foldseek results table";
    }

    if(sortState.direction == "asc" && sortState.active == "sequenceIdentity") { 
      tag = "foldseek_sequenceidentity_asc";
      gtagmsg = "Clicks on the sort be ascending order for sequence identity in the Foldseek results table";
    }
    if(sortState.direction == "desc" && sortState.active == "sequenceIdentity") {
      tag = "foldseek_sequenceidentity_desc";
      gtagmsg = "Clicks on the sort be descending order for sequence identity  in the Foldseek results table ";
    }

    const directions = {
      "ASC": "DESC",
      "DESC": "ASC"
    }

    if(flag === "pdb"){
      this.sort_direction_PDB = directions[sortState.direction.toUpperCase()];
      this.sort_column_PDB = sortState.active
      let species_name_filter_PDB;
      if(this.filteritemPDB !== ""){
        this.species_name_filter_PDB = this.filteritemPDB;
      }
      this.checkAndGetStructureData(this.recordsPDB, this.startPDB, this.sort_direction_PDB, this.sort_column_PDB, this.species_name_filter_PDB);
    }
    if(flag === "afdb"){
      this.sort_direction_AFDB = directions[sortState.direction.toUpperCase()];
      this.sort_column_AFDB = sortState.active
      let species_name_filter_AFDB;
      if(this.filteritemAFDB !== ""){
        this.species_name_filter_AFDB= this.filteritemAFDB;
      }
      this.checkAndGetAFDBData(this.recordsAFDB, this.startAFDB, this.sort_direction_AFDB, this.sort_column_AFDB, this.species_name_filter_AFDB);
    }
    this.gaService.eventEmitter('foldseek_table_action', 'FS_similar_structures', 'click', tag, gtagmsg);
  }

  filterTable(filterby, flag){
    if(flag === "pdb"){
      this.currentPDBPage = 0;
      this.filteritemPDB = filterby;
      this.startPDB = "0";
      this.species_name_filter_PDB = filterby;
      this.checkAndGetStructureData(this.recordsPDB, "0", this.sort_direction_PDB, this.sort_column_PDB, filterby);
    }
    if(flag === "afdb"){
      this.currentAFDBPage = 0;
      this.filteritemAFDB = filterby;
      this.startAFDB = "0";
      this.species_name_filter_AFDB = filterby;
      this.checkAndGetAFDBData(this.recordsAFDB, "0", this.sort_direction_AFDB, this.sort_column_AFDB, filterby);
    }
    //this.changeDetectorRef.detectChanges();
    this.gaService.eventEmitter('foldseek_table_action', 'FS_similar_structures', 'click', 'foldseek_taxfilter', 'Clicks on the "Taxonomy filter" input box on the Foldseek results table');
  }

  clearFilter(flag){
    if(flag === "pdb"){
      this.currentPDBPage = 0;
      this.filteritemPDB = "";
      this.species_name_filter_PDB = '';
      this.checkAndGetStructureData(this.recordsPDB, "0", this.sort_direction_PDB, this.sort_column_PDB, "");
    }
    if(flag === "afdb"){
      this.currentAFDBPage = 0;
      this.filteritemAFDB = "";
      this.species_name_filter_AFDB = '';
      this.checkAndGetAFDBData(this.recordsAFDB, "0", this.sort_direction_AFDB, this.sort_column_AFDB, "");
    }
    this.changeDetectorRef.detectChanges();
  }

  getApiUrl(flag, records, start, sort_direction, sort_column, species_name_filter){
    let apiUrl = "";
    const configUrl = this.configService.getConfig().searchApiUrl ? this.configService.getConfig().searchApiUrl : "https://test.alphafold.ebi.ac.uk/api/search";

    apiUrl = `${configUrl}?q=${this.accession}&type=structure`;

    if(flag){
      apiUrl = `${apiUrl}&foldseek_database=`+flag;
    }

    if(records){
      apiUrl = `${apiUrl}&rows=`+records;
    }

    if(start){
      apiUrl = `${apiUrl}&start=${start}`;
    }

    if((sort_column && sort_column != "") && (sort_direction && sort_direction != "")){
      if(sort_column === "evalue")
        apiUrl = apiUrl + '&sort='+sort_column+'+'+sort_direction;
      if(sort_column === "sequenceIdentity")
        apiUrl = apiUrl + '&sort=pident+'+sort_direction;
    }

    if (flag === "pdb"){
      if(species_name_filter && species_name_filter != ""){
        apiUrl = apiUrl + '&fq=organismScientificNames%3A*'+species_name_filter.trim()+'*';
      }
    }
    if (flag === "afdb50"){
      if(species_name_filter && species_name_filter != ""){
        apiUrl = apiUrl + '&fq=organismScientificName%3A*'+species_name_filter.trim()+'*';
      }
    }
    return apiUrl;
  }
  downloadTableInCSV(flag){
    const auth = this.configService.getConfig().apiKey ? `&key=${this.configService.getConfig().apiKey}` : "";
    let apiUrl;

    if(flag === "PDB"){
      apiUrl = this.getApiUrl("pdb", "10000", "0", this.sort_direction_PDB, this.sort_column_PDB, this.filteritemPDB);
    }
    if(flag === "AFDB"){
      apiUrl = this.getApiUrl("afdb50", "10000", "0", this.sort_direction_AFDB, this.sort_column_AFDB, this.filteritemAFDB);
    }
   
    this.gaService.eventEmitter(
      'foldseek_download', 'FS_similar_structures', 'click', 'foldseek_download_csv', 'Clicks on the "Download table (csv)" option on the Foldseek results table'
    );

    this.commonService.getApiDatawithStatus(apiUrl + auth).subscribe(async (response: HttpResponse<any>) => {
      const code = response.status;
      if(code === 200){
        this.isStructureCach = true;
        this.isStructureRunning = false;
        if( response.body.numFound !== 0){
          const data = response.body.docs;
          const similarStructureData = this.transformStructureDataforCSV(data);
          const fileName = `${this.accession}_${flag}_FS`;
          const finalData = XLSX.utils.json_to_sheet(similarStructureData);
          const workbook = {
            SheetNames: ['sheet1'], 
            Sheets: {
              sheet1: { ...finalData }, 
            },
          };
          XLSX.writeFile(workbook, `${fileName}.csv`);
        }else{
          if(flag === "PDB"){
            this.isPDBDownloadEnabled = false;
          }
          if(flag === "AFDB"){
            this.isAFDBDownloadEnabled = false;
          }
          
        }
      }
    },
    (error) => {
      const code = error.status;
      if(code === 404){
        if(flag === "PDB"){
          this.isPDBDownloadEnabled = false;
        }
        if(flag === "AFDB"){
          this.isAFDBDownloadEnabled = false;
        }
        this.changeDetectorRef.detectChanges();
      }
    });
  }
  
  updateStructureCacheFlag(flag) {
    this.isStructureCach = this.isPDBFoldseekCach && this.isAFDBFoldseekCach;
  }

  checkAndGetStructureData(records="", start="", sort_direction, sort_column, species_name_filter=""){
    const auth = this.configService.getConfig().apiKey ? `&key=${this.configService.getConfig().apiKey}` : "";

    const apiUrl = this.getApiUrl("pdb", records, start, sort_direction, sort_column, species_name_filter);

    this.isPDBLoading = true;

    this.commonService.getApiDatawithStatus(apiUrl + auth).subscribe(async (response: HttpResponse<any>) => {
      const code = response.status;
   
      if(code === 202){
        this.isStructureCach = true;
        this.isStructureRunning = true;
        if(response.body.message && (response.body.message.startsWith("Foldseek search job is currently running") || response.body.message.startsWith("Initiating new Foldseek search")))
        {
          this.isStructureRunning = true;
          this.subTimeout = setTimeout(() => {
          if(this.isPDBLoading) this.checkAndGetStructureData(records, start, sort_direction, sort_column, species_name_filter);
          }, 15000);
        }
      }
     
      if(code === 200){
        this.isPDBDownloadEnabled = true;
        this.isStructureCach = true;
        this.isStructureRunning = false;
        if( response.body.numFound !== 0){
          const data = response.body.docs;
          this.hasStructureData = 1;
          this.hasPDBData = 1
          this.errorPDB = "";
          this.totalStructure = response.body.numFound;
          const similarStructureData = this.transformStructureData(data);
          this.isPDBLoading = false;
          this.initViewer();
          // this.initViewer();
          this.pdbStructureDataSource = new MatTableDataSource<SimilarStructure>(similarStructureData);    
          this.pdbStructureDataSource.paginator = this.paginator3;
        }
      }
      this.changeDetectorRef.detectChanges();
    },
    (error) => {
      const code = error.status;
      this.isPDBLoading = false;
      this.initViewer();
      if(code === 404){
        this.isStructureRunning = false;
        this.hasPDBData = -1;
        this.totalStructure = 0;
        this.pdbStructureDataSource = null;
        this.changeDetectorRef.detectChanges();
        if(error.error.message === "Foldseek was not run for this accession."){
          this.isPDBFoldseekCach = false;
        }else if(error.error.message === "The search was completed successfully, but no matching results were found"){
          this.isPDBFoldseekCach = true;
          this.handlePDBError("", error.error.message);
        }else if(species_name_filter != ""){
          this.handlePDBError(species_name_filter, error.error.message);
        }
        this.updateStructureCacheFlag("pdb");
      }
      if(code === 422 || code === 302 || code === 500 || code === 0 || code === 429){
        this.isStructureRunning = false;
        this.isStructureFail = true;
        this.changeDetectorRef.detectChanges();
      }
      
    });
  }

  checkAndGetAFDBData(records="", start="", sort_direction, sort_column, species_name_filter=""){
    const auth = this.configService.getConfig().apiKey ? `&key=${this.configService.getConfig().apiKey}` : "";
  
    const apiUrl = this.getApiUrl("afdb50", records, start, sort_direction, sort_column, species_name_filter);

    this.isAFDBLoading = true;

    this.commonService.getApiDatawithStatus(apiUrl + auth).subscribe(async (response: HttpResponse<any>) => {
      const code = await Number(response.status);
      if(code === 202){
        this.isStructureCach = true;
        this.isStructureRunning = true;
        if(response.body.message && (response.body.message.startsWith("Foldseek search job is currently running") || response.body.message.startsWith("Initiating new Foldseek search")))
        {
          this.isStructureRunning = true;
          this.subTimeout = setTimeout(() => {
          if(this.isAFDBLoading) this.checkAndGetAFDBData(records, start, sort_direction, sort_column, species_name_filter);
          }, 15000);
        }
      }
      if(code === 200){
        this.isAFDBDownloadEnabled = true;
        this.isStructureCach = true;
        this.isStructureRunning = false;
        if( response.body.numFound !== 0){
          const data = response.body.docs;
          this.hasStructureData = 1;
          this.hasAFDBData = 1;
          this.errorAFDB = "";
          this.totalAFDBStructure = response.body.numFound;
          const similarStructureData = this.transformAFDBStructureData(data);
          this.isAFDBLoading = false;
          this.initViewer();
          
          this.afdbStructureDataSource = new MatTableDataSource<SimilarStructure>(similarStructureData);    
          this.afdbStructureDataSource.paginator = this.paginator3;
        }
      }
      this.changeDetectorRef.detectChanges();
    },
    (error) => {
      const code = error.status;
      this.isAFDBLoading = false;
      this.initViewer();
      if(code === 404){
        this.isStructureRunning = false;
        this.hasAFDBData = -1;
        this.totalAFDBStructure = 0;
        this.afdbStructureDataSource = null;
        this.changeDetectorRef.detectChanges();

        if(error.error.message === "Foldseek was not run for this accession."){
          this.isAFDBFoldseekCach = false;
        }else if(error.error.message === "The search was completed successfully, but no matching results were found"){
          this.isAFDBFoldseekCach = true;
          this.handleAFDBError("", error.error.message);
        }else if(species_name_filter != ""){
          this.handleAFDBError(species_name_filter, error.error.message);
        }
        this.updateStructureCacheFlag("afdb");
      }
      if(code === 422 || code === 302 || code === 500 || code === 0 || code === 429){
        this.isStructureCach = false;
        this.isStructureRunning = false;
        this.isStructureFail = true;
        this.changeDetectorRef.detectChanges();
      }
    });
  }
 
  startStructureSearch(){
    const auth = this.configService.getConfig().apiKey ? `?key=${this.configService.getConfig().apiKey}` : "";
    const configUrl = this.configService.getConfig().structureSearchApiUrl ? this.configService.getConfig().structureSearchApiUrl : "https://test.alphafold.ebi.ac.uk/api/structure/search/";

    const apiUrl = `${configUrl}${this.accession}`;

    this.commonService.postApiData(apiUrl + auth, {}).subscribe(async (response) => {
      this.changeDetectorRef.detectChanges();
      if(response && (response.status == 202)){
        this.isStructureRunning = true;
        this.isStructureCach = true; 
        this.changeDetectorRef.detectChanges();
        this.checkAndGetStructureData(this.recordsPDB, this.startPDB, this.sort_direction_PDB, this.sort_column_PDB, this.species_name_filter_PDB);
        this.checkAndGetAFDBData(this.recordsAFDB, this.startAFDB, this.sort_direction_AFDB, this.sort_column_AFDB, this.species_name_filter_AFDB);
        this.gaService.eventEmitter(
          'foldseek_search', 'FS_similar_structures', 'click', 'foldseek_find_trigger', 'Clicks on the button "Find Similar structures" to trigger search'
        );
      }
      if(response && (response.status == 200 || response.body?.message.startsWith("There are results for accession"))){
        this.isStructureRunning = false;
        this.isStructureCach = true; 
        this.changeDetectorRef.detectChanges();
        this.checkAndGetStructureData(this.recordsPDB, this.startPDB, this.sort_direction_PDB, this.sort_column_PDB, this.species_name_filter_PDB);
        this.checkAndGetAFDBData(this.recordsAFDB, this.startAFDB, this.sort_direction_AFDB, this.sort_column_AFDB, this.species_name_filter_AFDB);
        this.gaService.eventEmitter(
          'foldseek_search', 'FS_similar_structures', 'click', 'foldseek_find_trigger', 'Clicks on the button "Find Similar structures" to trigger search'
        );
      }
    },
    (error) => {
      const code =  error.status;
      if(code === 404){
        this.isStructureCach = false;
        this.isStructureRunning = false;
      }
     
      if(code === 422 || code === 302 || code === 500){
        this.isStructureFail = true;
      }
      this.changeDetectorRef.detectChanges();
    });
  }
  
  getTitle(accession, reviewedStatus, referenceLabel) {
    let tempTitle = "Unavailable";
      
    if(accession){
      if(reviewedStatus && referenceLabel) {
        tempTitle =  `${accession}*${referenceLabel}*${reviewedStatus}`;
      }
      if(reviewedStatus && !referenceLabel){
        tempTitle =  `${accession}*${referenceLabel}*${reviewedStatus}`;
      }
      if(!reviewedStatus && referenceLabel){
        tempTitle =  `${accession}*${referenceLabel}*${reviewedStatus}`;
      }
      if(!reviewedStatus && !referenceLabel){
        tempTitle =  `${accession}*${referenceLabel}*${reviewedStatus}`;
      }
    }

    return tempTitle;
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    const tabLable = tabChangeEvent.tab.textLabel;
    if(tabLable.includes("PDB structures")){
      this.gaService.eventEmitter(
        'foldseek_results', 'FS_similar_structures', 'click', 'foldseek_PDB_structures', 'Click on the tab to see PDB structures'
      );
    }
    if(tabLable.includes("AFDB50 structures")){
      this.gaService.eventEmitter(
        'foldseek_results', 'FS_similar_structures', 'click', 'foldseek_AFDB_structures', 'Click on the tab to see AFDB50 structures'
      );
    }
  }

  getLink(database, target) {
    if (database === "pdb") {
      const [entry, ,protein] = target.split("_");
      return protein ? 
        `${this.configService.getConfig().pdbeBaseApiUrl}/entry/pdb/${entry}/protein/${protein}` : 
        `${this.configService.getConfig().pdbeBaseApiUrl}/entry/pdb/${entry}`;
    }
    return `/entry/${target}`;
  }
  
  getResidueRange(database, data){
    let rangetoshow = '';
    if(database === "pdb") {
      if(data.start){
        const length = data.start.length;
        for(let index = 0; index < length; index++){
          const inrangetoshow = `${data.targetAccessions[index]}: ${data.start[index]}-${data.end[index]}`;
    
          if (rangetoshow) {
              rangetoshow += ',  ';  
          }
          rangetoshow += inrangetoshow; 
        }
      }else{
        rangetoshow = "N/A";
      }
    } 
    return rangetoshow
  }
  
  transformAFDBStructureData(data) {
    const similarClusterData = [];
    if(data){
    for (let i = 0; i < data.length; i++) {
      similarClusterData.push({
        "refLinkId": this.getLink(data[i].database, data[i].target),
        "afdbAccessions": this.getTitle(data[i].target, data[i].isReviewed, data[i].isReferenceProteome),
        "targetToShow": data[i].entryId,
        "uniprotDescriptions": data[i].uniprotDescription,
        "speciesNames": data[i].organismScientificName,
        "sequenceIdentity": data[i].pident ? data[i].pident : "N/A",
        "sequenceLength": data[i].uniprotEnd ? `1-${data[i].uniprotEnd}` : "N/A",
        "evalue": (data[i].evalue != "" || data[i].evalue != null || data[i].evalue != undefined) ? data[i].evalue.toExponential(2) : "N/A",
        "averagePlddt": data[i].globalMetricValue ?  data[i].globalMetricValue : "N/A",
        "alighin3D": this.superposeData.some(entry => entry.accession === data[i].target),
        "expanded": false,
        "hsps" : {
          "current_entry": this.afdb_accession,
          "accession": data[i].target,
          "query_sequence": data[i].qaln,
          "match_sequence": data[i].qaln,
          "match_accession": data[i].taln,
          "qstart": data[i].qstart,
          "qend": data[i].qend,
          "tstart": data[i].tstart,
          "tend": data[i].tend,
          "sequence_stats": [],
          "targetToShow": data[i].entryId,
        }
      });

      const foldseekWithDesc = { 
        ...data[i], 
        targetToShow: data[i].entryId,
        desc: data[i].uniprotDescription, 
        refLinkId: this.getLink(data[i].database, data[i].target),
      };

      if (data[i].database === "afdb50") {
        foldseekWithDesc.tstructure = {
          "url": `https://alphafold.ebi.ac.uk/files/AF-${data[i].target}-F1-model_v4.bcif`,
          "format": 'cif',
          "binary": true,
        }
      }

      this.apiData[data[i].target] = foldseekWithDesc;
    }
    return similarClusterData;
  }}

  transformStructureData(data) {
   
    const similarClusterData = [];
   
    if(data){
    for (let i = 0; i < data.length; i++) {
      similarClusterData.push({
        "refLinkId": this.getLink(data[i].database, data[i].target),
        "afdbAccessions": this.getTitle(data[i].target, data[i].isReviewed, data[i].isReferenceProteome),
        "targetToShow": data[i].database === "pdb" ? data[i].target : data[i].entryId,
        "uniprotDescriptions": data[i].descriptions,
        "speciesNames":data[i].organismScientificNames,
        "sequenceIdentity": data[i].pident ? data[i].pident : "N/A",

        "sequenceLength": {
          "start":data[i].uniprotStarts, 
          "end":data[i].uniprotEnds,
          "targetAccessions":data[i].targetAccessions
        },
        "evalue": (data[i].evalue != "" || data[i].evalue != null || data[i].evalue != undefined) ? data[i].evalue.toExponential(2) : "N/A",
        "averagePlddt": data[i].database === "pdb" ? "N/A" : data[i].globalMetricValue,
        "resolution": data[i].database === "pdb" ? data[i].resolution != null ? data[i].resolution.toFixed(2) : "N/A" : "N/A",
        "alighin3D": this.superposeData.some(entry => entry.accession === data[i].target),
        "expanded": false,
        "hsps" : {
          "current_entry": this.afdb_accession,
          "accession": data[i].target,
          "query_sequence": data[i].qaln,
          "match_sequence": data[i].qaln,
          "match_accession": data[i].taln,
          "qstart": data[i].qstart,
          "qend": data[i].qend,
          "tstart": data[i].tstart,
          "tend": data[i].tend,
          "sequence_stats": [],
          "targetToShow": data[i].database === "pdb" ? data[i].target : data[i].entryId,
        }
      });

      const foldseekWithDesc = { 
        ...data[i], 
        targetToShow: data[i].database === "pdb" ? data[i].target : data[i].entryId,
        desc: data[i].database === "pdb" ? data[i].descriptions[0] : data[i].uniprotDescription, 
        refLinkId: this.getLink(data[i].database, data[i].target),
      };

      if (data[i].database === "afdb50") {
        foldseekWithDesc.tstructure = {
          "url": `https://alphafold.ebi.ac.uk/files/AF-${data[i].target}-F1-model_v4.bcif`,
          "format": 'cif',
          "binary": true,
        }
      }

      this.apiData[data[i].target] = foldseekWithDesc;
    }
    return similarClusterData;
  }}

  transformStructureDataforCSV(data) {
    const tableCSVData = [];
    if(data){
    for (let i = 0; i < data.length; i++) {

      let dataObj: any = {
        "Sr.No.": i+1
      }
      
      if (data[i].database === "pdb") {
        dataObj["PDB ID and chain"] = data[i].target 
        dataObj["Description"] = data[i].descriptions.join(', ')
        dataObj["Species"] = data[i].organismScientificNames.join(', ')
        dataObj["Residue Range"] = this.getResidueRange(data[i].database, {"start":data[i].uniprotStarts,"end":data[i].uniprotEnds,"targetAccessions":data[i].targetAccessions} )
        dataObj["E-value"] = (data[i].evalue != "" || data[i].evalue != null || data[i].evalue != undefined) ? data[i].evalue.toExponential(2) : "N/A",
        dataObj["Sequence identity"] = data[i].pident ? data[i].pident+"%" : "N/A"
      }

      if (data[i].database === "afdb50") {
        dataObj["AFDB accession"] =  data[i].entryId
        dataObj["Description"]=  data[i].uniprotDescription ? data[i].uniprotDescription : "N/A" 
        dataObj["Species"] = data[i].organismScientificName ? data[i].organismScientificName : "N/A"
        dataObj["Residue Range"] =  data[i].uniprotEnd ?  `1-${data[i].uniprotEnd}` : "N/A"
        dataObj["E-value"] = data[i].evalue ? (data[i].evalue.toExponential(2)).toString() : "N/A"
        dataObj["Sequence identity"] = data[i].pident ? data[i].pident+"%" : "N/A"
      }

      if (data[i].database === "pdb") {
        dataObj["Resolution (Å)"] = data[i].resolution != null ? data[i].resolution.toFixed(2) : "N/A";
      }

      if (data[i].database === "afdb50") {
        dataObj["Reviewed status"] = data[i].isReviewed,
        dataObj["Reference proteome status"] = data[i].isReferenceProteome,
        dataObj["Average pLDDT"] = data[i].globalMetricValue;
      }
      dataObj = {
        ...dataObj,
        "Query start position" : data[i].qstart,
        "Query end position" : data[i].qend,
        "Aligned query sequence with gaps" : data[i].qaln,
        
        "Target start position" : data[i].tstart,
        "Target end position" : data[i].tend,
        "Aligned target sequence with gaps": data[i].taln,
      }
      tableCSVData.push(dataObj);
    }
    return tableCSVData;
  }}
}
