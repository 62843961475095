
<div class="row threedviewer">
    <div class="columns structureTitleBox">
      <div class="filledBox">
        <span class="title">Similar structures</span>
        <span class="paeDescription">
            Discover similar structures from the Protein Data Bank (PDB) and the AlphaFold Database clustered to 50% sequence identity (AFDB50)  using <a (click)= "gaService.eventEmitter(
                'foldseek_clickthrough', 'FS_similar_structures', 'click', 'foldseek_url', 'Clicks on the foldseek link from the description of table')" href="https://github.com/steineggerlab/foldseek" target="_blank">Foldseek</a>.
        </span>
      </div>
    </div>  
    
    <div class="columns stage-box" *ngIf="isStructureCach === true && (hasPDBData == 1 || hasAFDBData == 1)">
        <div class="frame-parent">
            <div class="be-the-first-to-explore-parent"> 
                <div class="struct-table-wrapper">
                    <div class="struct-table">
                        <mat-tab-group #tabGroup (selectedTabChange)="tabChanged($event)">

                            <mat-tab class="mat-tab-title" [label]="''" [disabled]="isPDBLoading">
                                <ng-template mat-tab-label>
                                    PDB structures&nbsp;
                                    <span *ngIf="!isPDBLoading">
                                    ({{ totalStructure }})
                                    </span>
                                    <!-- Spinner shown only when isPDBLoading is true -->
                                    <span *ngIf="isPDBLoading"  style="display: inline-block; width: 20px; margin-left: 8px;">
                                    <svg  width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z" class="spinner_aj0A" stroke="#707372" fill="#707372" />
                                    </svg>
                                    </span>
                                </ng-template>


                                <div *ngIf="pdbZeroResultsMsg !== ''" class="row threedViewer structureContainer mobile-error-msg">
                                    <p *ngIf="hasPDBData === -1 && totalStructure == 0" class="noRecMsg">{{ pdbZeroResultsMsg }} </p>
                                </div>

                                <div class="download-first-row" *ngIf="pdbZeroResultsMsg === ''">
                                    <div class="link-button-download" class="row threedViewer structureContainer-1">
                                        <div class="download-data"><a class="nonMobile"
                                            [ngClass]="isPDBDownloadEnabled === true && isPDBLoading === false ? '' : 'disabled-link'"
                                            (click)="isPDBDownloadEnabled ? downloadTableInCSV('PDB') : null">Download table (csv)</a></div>
                                    </div>
                            
                                    <div class="row threedViewer structureContainer filterWrapper">
                                        
                                        <div style="position: relative">
                                          <input
                                            #filterBox
                                            class="vf-form__input searchLine"
                                            autocomplete="off"
                                            placeholder="Search species"
                                            (keyup.enter)="filterTable(filterBox.value, 'pdb')"
                                            id="filteritemPDB"
                                            [(value)]="filteritemPDB"
                                          />
                                          <span
                                            *ngIf="filterBox.value === ''"
                                            class="filtericon"
                                            (click)="filterTable(filterBox.value, 'pdb')"
                                          >
                                            <img src="assets/img/Search.svg" />
                                          </span>
                                  
                                          <span
                                            *ngIf="filterBox.value !== ''"
                                            class="filtericon"
                                            (click)="clearFilter('pdb')"
                                          >
                                            <img src="assets/img/crossicon.svg" />
                                          </span>
                                        </div>
                                    </div>
                                    
                                </div>
                               
                                <div  *ngIf="pdbZeroResultsMsg === ''">

                                    <table
                                        mat-table
                                        [dataSource]="pdbStructureDataSource"
                                        class="mat-elevation-z8 structureTable"
                                        matSort
                                        matSortActive="evalue"
                                        matSortDirection="desc"
                                        (matSortChange)="sortTable($event, 'pdb')"
                                        matSortDisableClear
                                        multiTemplateDataRows
                                    >   

                                    <ng-container matColumnDef="expand">
                                        <th class="firstexpand arrow" mat-header-cell *matHeaderCellDef></th>
                                        <td  mat-cell *matCellDef="let element" class="arrow-box" [ngClass]="!isExpanded(element) ? '' : 'arrow-active'">
                                            <div class="nonMobile">
                                                <button (click)="toggleRowPDB(element); $event.stopPropagation()"  mat-icon-button aria-label="expand row" class="nonMobile icon-button">
                                                    <img *ngIf="!isExpanded(element)" src="assets/img/ep_arrow-up-bold.svg" alt="uparrow" class="unchecked-icon">
                                                    <img *ngIf="isExpanded(element)" src="assets/img/arrow_drop_down_24px.svg" alt="downarrow" class="unchecked-icon">
                                                </button>
                                            </div>
                                            <div class="mobile-view">
                                                <div class="mobile-wrapfirsttd">
                                                    <button (click)="toggleRowPDB(element); $event.stopPropagation()"  mat-icon-button aria-label="expand row" class="icon-button">
                                                        <span *ngIf="!isExpanded(element)" class="mobile-arrow-title-text"> Open pairwise alignment  <img *ngIf="!isExpanded(element)" src="assets/img/down-arrow.svg" alt="uparrow" class="newimg"></span>
                                                        <span *ngIf="isExpanded(element)" class="mobile-arrow-title-text"> Close pairwise alignment   <img  src="assets/img/arrow-dropping.svg" alt="downarrow" class="newimg"></span>
    
                                                    </button>
    
                                                    <div class="mobile-align-3d">
                                                        <span class="mobile-arrow-title align3d-for-mobile"> Align in 3D </span>
                                                        <div class="switch-af">
                                                            <input type="checkbox" [disabled]="disabledState[element.afdbAccessions.split('*')[0]] || false" [checked]="checkboxState[element.afdbAccessions.split('*')[0]] || false" id="{{element.afdbAccessions.split('*')[0]}}" value="{{element.afdbAccessions.split('*')[0]}}" (change)="superposeSliderToggle($event,element.afdbAccessions.split('*')[0])" />
                                                            <label for="{{element.afdbAccessions.split('*')[0]}}">
                                                            
                                                            <img src="../../assets/img/FatX.svg" alt="FatX" class="unchecked-icon">
                                                            <img src="../../assets/img/base-switch_icon.svg" alt="check" class="checked-icon">
        
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="expandedDetail">
                                        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumnsStructureWithExpand.length" class="expand-column">
                                          <div class="example-element-detail" *ngIf="isExpanded(element)">
                                            <app-sequence-card [hsps]="element.hsps" [isExpandStructure]="true"></app-sequence-card>
                                          </div>
                                        </td>
                                    </ng-container>
                                    
                                   
                                    <ng-container matColumnDef="afdbAccessions">
                                        <th class="first-pdbid" mat-header-cell *matHeaderCellDef>
                                           <span>PDB ID <br>and chain</span>
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                        <span class="mobile-view mobile-view-title">PDB ID and <br>chain</span>
                                        <span *ngIf="element.afdbAccessions === 'Unavailable'"
                                            >{{ element.targetToShow }} 
                                            <span class="mobile-view"><br></span>  
                                            {{ element.uniprotDescriptions }}
                                        </span>
                            
                                        <span
                                            class="titleBadges"
                                            *ngIf="element.afdbAccessions !== 'Unavailable'">
                                            <a class="pdbid" (click)= "gaService.eventEmitter(
                                        'foldseek_clickthrough', 'FS_similar_structures', 'click', 'foldseek_PDB_url', 'Clicks on PDB URLs in the Foldseek results table')" href="{{element.refLinkId}}" target="_blank">{{ element.targetToShow }}</a>
                                            
                                        </span>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="descriptions">
                                        <th class="sec-desc" mat-header-cell *matHeaderCellDef>Description</th>
                                        <td mat-cell *matCellDef="let element">
                                       
                                        <span class="mobile-view mobile-view-title">Description</span>
                                        <span class="mobile-view"> <div class="
                                             moreinfo" appDescription [descriptionData]="element.uniprotDescriptions" [isMobileDesc]="true"> </div> </span>

                                        <div class="nonMobile moreinfo" appDescription [descriptionData]="element.uniprotDescriptions" [isMobileDesc]="false"> </div>
                                       
                                        </td>
                                    </ng-container>
                            
                                    <ng-container matColumnDef="speciesNames">
                                        <th class="third-species" mat-header-cell *matHeaderCellDef>Species</th>
                                        <td mat-cell *matCellDef="let element">
                                       
                                        <span class="mobile-view mobile-view-title">Species</span>
                                        <span class="mobile-view"> <i> <div class=" moreinfo" appDescription [descriptionData]="element.speciesNames" [isMobileDesc]="true"> </div></i> </span>
                                        
                                        <i><div class="nonMobile moreinfo" appDescription [descriptionData]="element.speciesNames" [isMobileDesc]="false"> </div></i>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="sequenceLength">
                                        <th class="fourth-range" mat-header-cell *matHeaderCellDef>
                                            Residue <br/>range
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                        <span class="mobile-view mobile-view-title">Residue range</span>
                                            <div class="moreinforesidue" appResidueRange [residueData]="element.sequenceLength"></div>
                                        </td>
                                    </ng-container>
                            
                                    <ng-container matColumnDef="evalue">
                                        <th class="eval" mat-header-cell *matHeaderCellDef mat-sort-header="evalue">
                                            E-value
                                            <div class="label-tooltip" style="display: flex; flex-direction: row;">
                                                 <svg class="que-svg" viewBox="0 0 24 24">
                                                 <path fill="#373A36"
                                                         d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"/>
                                                 </svg>
                                                 <span class="label-header-tooltip-text" style="font-size: 14px; font-weight: 400; text-align: left;">
                                                    Likelihood of a match between <br>the query and target sequence <br> in a structural alignment. <br>The lower the E-value, the more<br> significant the alignment. 
                                                 </span>
                                             </div>
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            <div class="mobile-view mobile-view-title">
                                                <div style="display: flex; flex-direction: row;">
                                                    E-value
                                                    <div class="label-tooltip">
                                                        <svg class="que-svg" viewBox="0 0 24 24">
                                                            <path fill="#373A36"
                                                                    d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"/>
                                                        </svg>
                                                        <span class="label-header-tooltip-text" style="font-size: 14px; font-weight: 400; text-align: left;">
                                                            Likelihood of a match between <br>the query and target sequence <br> in a structural alignment. <br>The lower the E-value, the more<br> significant the alignment. 
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            {{ element.evalue }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="sequenceIdentity">
                                        <th class="seq-identity" mat-header-cell *matHeaderCellDef  mat-sort-header>
                                            <div style="display: flex; flex-direction: row; align-items:end;">
                                                Seq. <br>identity
                                                <div class="label-tooltip-res">
                                                    <svg class="que-svg" viewBox="0 0 24 24">
                                                    <path fill="#373A36"
                                                            d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"/>
                                                    </svg>
                                                    <span class="label-tooltip-text-res" style="font-size: 14px; font-weight: 400; text-align: left;">
                                                        Percentage of identical <br>residues between aligned <br>sequences over the<br> aligned length.
                                                    </span>
                                                </div>
                                            </div>
                                           
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            <span class="mobile-view mobile-view-title">
                                                <div style="display: flex; flex-direction: row; align-items:end;">
                                                Seq. identity
                                                    <div class="label-tooltip">
                                                        <svg class="que-svg" viewBox="0 0 24 24">
                                                        <path fill="#373A36"
                                                                d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"/>
                                                        </svg>
                                                        <span class="label-header-tooltip-text" style="font-size: 14px; font-weight: 400; text-align: left;">
                                                            Percentage of identical <br>residues between aligned <br>sequences over the<br> aligned length.
                                                        </span>
                                                    </div>
                                                </div>
                                            </span>
                                        {{ element.sequenceIdentity }}%
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="resolution">
                                        <th class="seventh-res thirdid" mat-header-cell *matHeaderCellDef>
                                            Res.
                                            <div style="display: flex;"> (Å)
                                                <div class="label-tooltip-res" style="display: flex; flex-direction: row;">
                                                   
                                                    <svg class="que-svg" viewBox="0 0 24 24">
                                                    <path fill="#373A36"
                                                            d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"/>
                                                    </svg>
                                                    <span class="label-tooltip-text-res" style="font-size: 14px; font-weight: 400; text-align: left;">
                                                        Resolution. Indicates the level <br>of detail present in the 3D  <br>structure. Smaller value means  <br>finer details of the  structure<br> and higher quality.
                                                    </span>
                                                </div>
                                            </div>
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            <span class="mobile-view mobile-view-title"> 
                                                <div style="display: flex; flex-direction: row; align-items: end;">
                                                    Resolution (Å)
                                                    <div class="label-tooltip">
                                                        <svg class="que-svg" viewBox="0 0 24 24">
                                                            <path fill="#373A36"
                                                                    d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"/>
                                                        </svg>
                                                        <span class="label-header-tooltip-text" style="font-size: 14px; font-weight: 400; text-align: left;">
                                                            Resolution. Indicates the level <br>of detail present in the 3D  <br>structure. Smaller value means  <br>finer details of the  structure<br> and higher quality.
                                                        </span>
                                                    </div>
                                                </div>
                                            </span>
                                            {{ element.resolution }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="alighin3D">
                                        <th class="eight-align" mat-header-cell *matHeaderCellDef>
                                            Align <br/>in 3D
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="align3dfor-nonmobile">
                                            <div class="switch-af nonMobile">
                                                <input type="checkbox" [disabled]="disabledState[element.afdbAccessions.split('*')[0]] || false" [checked]="checkboxState[element.afdbAccessions.split('*')[0]] || false" id="{{element.afdbAccessions.split('*')[0]}}" value="{{element.afdbAccessions.split('*')[0]}}" (change)="superposeSliderToggle($event,element.afdbAccessions.split('*')[0]); $event.stopPropagation();" />
                                                <label (click)="$event.stopPropagation();" for="{{element.afdbAccessions.split('*')[0]}}">
                                                
                                                <img src="../../assets/img/FatX.svg" alt="FatX" class="unchecked-icon">
                                                <img src="../../assets/img/base-switch_icon.svg" alt="check" class="checked-icon">

                                                </label>
                                            </div>
                                        </td>
                                    </ng-container>
                                   
                                    <tr mat-header-row *matHeaderRowDef="displayedColumnsStructureWithExpand"></tr>
                                    <tr mat-row *matRowDef="let element; columns: displayedColumnsStructureWithExpand;"
                                        class="example-element-row"
                                        [ngClass]="isExpanded(element) ? 'example-expanded-row' : ''" 
                                        
                                        (click)="expandRow(element)">
                                    </tr>
                                    
                                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                                    
                                    <ng-container *ngFor="let row of pdbStructureDataSource?.data">
                                        <ng-container *ngIf="isExpanded(row)" >
                                          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow" class="example-detail-row"></tr>
                                        </ng-container>
                                    </ng-container>

                                </table>
                                    
                                </div>
                                
                                <div  *ngIf="pdbZeroResultsMsg === ''" class="row threedViewer structureContainer">
                                    <p *ngIf="hasPDBData === 1 && totalStructure == 0" class="noRecMsg">{{ errorPDB }}</p>
                                </div>
                                
                                <div *ngIf="pdbZeroResultsMsg === ''">
                                    <mat-paginator
                                        [length]="totalStructure"
                                        [pageSize]="recordsPDB"
                                        [pageIndex]="currentPDBPage"
                                        [pageSizeOptions]="[5, 10, 25, 50, 100]"
                                        aria-label="Select page"
                                        (page)="handlePageEvent($event,'pdb')"
                                    >
                                    </mat-paginator>
                                </div> 
                            </mat-tab>
                            <mat-tab class="mat-tab-title" [label]="''" [disabled]="isAFDBLoading">
                                <ng-template mat-tab-label>
                                  AFDB50 structures&nbsp;
                                  <span *ngIf="!isAFDBLoading">
                                    ({{ totalAFDBStructure }})
                                  </span>
                                  <!-- Spinner shown only when isAFDBLoading is true -->
                                  <span *ngIf="isAFDBLoading"  style="display: inline-block; width: 20px; margin-left: 8px;">
                                    <svg  width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z" class="spinner_aj0A" stroke="#707372" fill="#707372" />
                                    </svg>
                                  </span>
                                </ng-template>

    
                                <div *ngIf="afdbZeroResultsMsg !== ''" class="row threedViewer structureContainer ">
                                    <p *ngIf="hasAFDBData === -1 && totalAFDBStructure == 0" class="noRecMsg">{{ afdbZeroResultsMsg }}</p>
                                </div>
                               
                                <div class="download-first-row" *ngIf="afdbZeroResultsMsg === ''">
                                    <div class="link-button-download" class="row threedViewer structureContainer-1">
                                        <div class="download-data">
                                            <a class="nonMobile"
                                            [ngClass]="isAFDBDownloadEnabled === true && isAFDBLoading === false ? '' : 'disabled-link'"
                                            (click)="isAFDBDownloadEnabled ? downloadTableInCSV('AFDB') : null">Download table (csv)</a>
                                        </div>
                                    </div>

                                    <div class="row threedViewer structureContainer filterWrapper">
                                        <div style="position: relative">
                                        <input
                                            #filterBox2
                                            class="vf-form__input searchLine"
                                            autocomplete="off"
                                            placeholder="Search species"
                                            (keyup.enter)="filterTable(filterBox2.value, 'afdb')"
                                            id="filteritemAFDB"
                                            [(value)]="filteritemAFDB"
                                        />
                                        <span
                                            *ngIf="filterBox2.value === ''"
                                            class="filtericon"
                                            (click)="filterTable(filterBox2.value, 'afdb')"
                                        >
                                            <img src="assets/img/Search.svg" />
                                        </span>
                                
                                        <span
                                            *ngIf="filterBox2.value !== ''"
                                            class="filtericon"
                                            (click)="clearFilter('afdb')"
                                        >
                                            <img src="assets/img/crossicon.svg" />
                                        </span>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="afdbZeroResultsMsg === ''">
                                    <table
                                        mat-table
                                        [dataSource]="afdbStructureDataSource"
                                        class="mat-elevation-z8 structureTable"
                                        matSort
                                        matSortActive="evalue"
                                        matSortDirection="desc"
                                        (matSortChange)="sortTable($event, 'afdb')"
                                        matSortDisableClear
                                        multiTemplateDataRows
                                    >

                                    <ng-container matColumnDef="expand">
                                        <th  class="firstexpand arrow" mat-header-cell *matHeaderCellDef></th>
                                        <td  mat-cell *matCellDef="let element" class="arrow-box" [ngClass]="!isExpandedAFDB(element) ? '' : 'arrow-active'">
                                            <div class="nonMobile">
                                                <button (click)="toggleRowAFDB(element); $event.stopPropagation()"  mat-icon-button aria-label="expand row" class="nonMobile icon-button">
                                                    <img *ngIf="!isExpandedAFDB(element)" src="assets/img/ep_arrow-up-bold.svg" alt="uparrow" class="unchecked-icon">
                                                    <img *ngIf="isExpandedAFDB(element)" src="assets/img/arrow_drop_down_24px.svg" alt="downarrow" class="unchecked-icon">
                                                </button>
                                            </div>
                                            <div class="mobile-view">
                                                <div class="mobile-wrapfirsttd">
                                                    <button (click)="toggleRowAFDB(element); $event.stopPropagation()"  mat-icon-button aria-label="expand row" class="icon-button">
                                                        <span *ngIf="!isExpandedAFDB(element)" class="mobile-arrow-title-text"> Open pairwise alignment  <img *ngIf="!isExpandedAFDB(element)" src="assets/img/down-arrow.svg" alt="uparrow" class="newimg"></span>
                                                        <span *ngIf="isExpandedAFDB(element)" class="mobile-arrow-title-text"> Close pairwise alignment   <img  src="assets/img/arrow-dropping.svg" alt="downarrow" class="newimg"></span>
    
                                                    </button>
    
                                                    <div class="mobile-align-3d">
                                                        <span class="mobile-arrow-title align3d-for-mobile"> Align in 3D </span>
                                                            <div class="switch-af">
                                                                <input type="checkbox" [disabled]="disabledState[element.afdbAccessions.split('*')[0]] || false" [checked]="checkboxState[element.afdbAccessions.split('*')[0]] || false" id="{{element.afdbAccessions.split('*')[0]}}" value="{{element.afdbAccessions.split('*')[0]}}" (change)="superposeSliderToggle($event,element.afdbAccessions.split('*')[0])" />
                                                                <label for="{{element.afdbAccessions.split('*')[0]}}">
                                                                
                                                                <img src="../../assets/img/FatX.svg" alt="FatX" class="unchecked-icon">
                                                                <img src="../../assets/img/base-switch_icon.svg" alt="check" class="checked-icon">
            
                                                                </label>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="expandedDetailAFDB">
                                        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumnsAFDBStructure.length">
                                          <div class="example-element-detail" *ngIf="isExpandedAFDB(element)">
                                            <app-sequence-card [hsps]="element.hsps" [isExpandStructure]="true"></app-sequence-card>
                                          </div>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="afdbAccessions">
                                        <th class="first" mat-header-cell *matHeaderCellDef>
                                            <div style="display: flex; align-items: center; flex-direction: row;">
                                                <span> AFDB accession and description</span>
                                            </div> 
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                        <span class="mobile-view mobile-view-title"> AFDB accession and <br>description</span>

                                        <div class="target-desc mobile-view">
                                            <span
                                                class="titleBadges"
                                                *ngIf="element.afdbAccessions !== 'Unavailable'">
                                                <a (click)= "gaService.eventEmitter(
                                            'foldseek_clickthrough', 'FS_similar_structures', 'click', 'foldseek_AFDB_urls', 'Clicks on and AFDB accession URL in the foldseek results table')" class='accessLink' href="{{element.refLinkId}}" target="_blank">{{ element.targetToShow }} </a>
                                                <app-entry-status [isSummary]="false" [clusterTable]="true" [isProteome]="element.afdbAccessions.split('*')[1]" [isReviewed]="element.afdbAccessions.split('*')[2]"></app-entry-status>
                                                
                                            </span>
                                            <div class="descEllipsestrict">
                                                {{  element.uniprotDescriptions }}
                                            </div>
                                        </div>
                                        
                            
                                        <div class="target-desc nonMobile">
                                            <span
                                                class="titleBadges"
                                                *ngIf="element.afdbAccessions !== 'Unavailable'">
                                                <a (click)= "gaService.eventEmitter(
                                            'foldseek_clickthrough', 'FS_similar_structures', 'click', 'foldseek_AFDB_urls', 'Clicks on and AFDB accession URL in the foldseek results table')" class='accessLink' href="{{element.refLinkId}}" target="_blank">{{ element.targetToShow }} </a>
                                                <app-entry-status [isSummary]="false" [clusterTable]="true" [isProteome]="element.afdbAccessions.split('*')[1]" [isReviewed]="element.afdbAccessions.split('*')[2]"></app-entry-status>
                                                
                                            </span>
                                            <div class="label-tooltip" *ngIf="element.uniprotDescriptions.length > 25">
                                        
                                                <p class="descEllipse">{{ element.uniprotDescriptions.slice(0,25) }}... </p>
                                                <span class="label-tooltip-text">
                                                {{  element.uniprotDescriptions }}
                                                </span>
                                            </div>
                                
                                            <div *ngIf="element.uniprotDescriptions.length <= 25" class="descEllipsestrict">
                                                {{  element.uniprotDescriptions }}
                                            </div>
                                        </div>
                                    </td>
                                    </ng-container>
                            
                                    <ng-container matColumnDef="speciesNames">
                                        <th class="third-species" mat-header-cell *matHeaderCellDef>Species</th>
                                        <td mat-cell *matCellDef="let element">
                                       
                                        <span class="mobile-view mobile-view-title">Species</span>
                                        <span class="mobile-view"> <i>{{  element.speciesNames }}</i> </span>
                                        
                                        <span class="nonMobile">
                                            <div class="label-tooltip" *ngIf="element.speciesNames.length > 20">
                                           
                                                <p class="descEllipseSpec"><i>{{ element.speciesNames.slice(0,20) }}... </i> </p>
                                                <span class="label-tooltip-text">
                                                {{  element.speciesNames }}
                                                </span>
                                            </div>
                                
                                            <div *ngIf="element.speciesNames.length <= 20">
                                                <p class="descEllipseSpec-norm"><i> {{  element.speciesNames }}</i></p>
                                            </div>
                                        </span>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="sequenceLength">
                                        <th class="fourth" mat-header-cell *matHeaderCellDef>
                                        Residue <br/>range
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                        <span class="mobile-view mobile-view-title">Residue range</span>
                                        {{ element.sequenceLength }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="evalue">
                                        <th class="eval" mat-header-cell *matHeaderCellDef mat-sort-header="evalue">
                                            E-value
                                            <div class="label-tooltip" style="display: flex; flex-direction: row;">
                                                <svg class="que-svg" viewBox="0 0 24 24">
                                                <path fill="#373A36"
                                                        d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"/>
                                                </svg>
                                                <span class="label-header-tooltip-text" style="font-size: 14px; font-weight: 400; text-align: left;">
                                                    Likelihood of a match between <br>the query and target sequence <br> in a structural alignment. <br>The lower the E-value, the more<br> significant the alignment. 
                                                 </span>
                                            </div>
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            <span class="mobile-view mobile-view-title">
                                                <div style="display: flex; flex-direction: row;">
                                                    E-value
                                                    <div class="label-tooltip">
                                                        <svg class="que-svg" viewBox="0 0 24 24">
                                                            <path fill="#373A36"
                                                                    d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"/>
                                                        </svg>
                                                        <span class="label-header-tooltip-text" style="font-size: 14px; font-weight: 400; text-align: left;">
                                                            Likelihood of a match between <br>the query and target sequence <br> in a structural alignment. <br>The lower the E-value, the more<br> significant the alignment. 
                                                        </span>
                                                    </div>
                                                </div>
                                            </span>
                                            {{ element.evalue }}
                                        </td>
                                    </ng-container>
                            
                                    <ng-container matColumnDef="sequenceIdentity">
                                        <th class="fourth thirdid" mat-header-cell *matHeaderCellDef  mat-sort-header>
                                            <div style="display: flex; flex-direction: row; align-items:end;">
                                                Seq. <br> identity
                                                <div class="label-tooltip">
                                                    <svg class="que-svg" viewBox="0 0 24 24">
                                                    <path fill="#373A36"
                                                            d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"/>
                                                    </svg>
                                                    <span class="label-header-tooltip-text" style="font-size: 14px; font-weight: 400; text-align: left;">
                                                        Percentage of identical <br>residues between aligned <br>sequences over the<br> aligned length.
                                                    </span>
                                                </div>
                                            </div>
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                        <span class="mobile-view mobile-view-title">
                                            <div style="display: flex; flex-direction: row; align-items:end;">
                                            Seq. identity
                                                <div class="label-tooltip">
                                                    <svg class="que-svg" viewBox="0 0 24 24">
                                                    <path fill="#373A36"
                                                            d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"/>
                                                    </svg>
                                                    <span class="label-header-tooltip-text" style="font-size: 14px; font-weight: 400; text-align: left;">
                                                        Percentage of identical <br>residues between aligned <br>sequences over the<br> aligned length.
                                                    </span>
                                                </div>
                                            </div>
                                        </span>
                                        {{ element.sequenceIdentity }}%
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="averagePlddt">
                                        <th class="fifth" mat-header-cell *matHeaderCellDef>
                                        Average <br/>pLDDT
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                        <span class="mobile-view mobile-view-title"> Average pLDDT</span>
                                        {{ element.averagePlddt }}
                                        </td>
                                    </ng-container>
                                
                                    <ng-container matColumnDef="alighin3D">
                                        <th class="fifth alignin3d" mat-header-cell *matHeaderCellDef>
                                            Align <br/>in 3D
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="align3dfor-nonmobile">
                                            
                                            <div class="switch-af nonMobile">
                                                <input type="checkbox" [disabled]="disabledState[element.afdbAccessions.split('*')[0]] || false" [checked]="checkboxState[element.afdbAccessions.split('*')[0]] || false" id="{{element.afdbAccessions.split('*')[0]}}" value="{{element.afdbAccessions.split('*')[0]}}" (mousedown)="$event.stopPropagation()" 
                                                (click)="$event.stopPropagation()"  (change)="superposeSliderToggle1($event,element.afdbAccessions.split('*')[0]); $event.stopPropagation();" />
                                                <label for="{{element.afdbAccessions.split('*')[0]}}">
                                                
                                                <img src="../../assets/img/FatX.svg" alt="FatX" class="unchecked-icon">
                                                <img src="../../assets/img/base-switch_icon.svg" alt="check" class="checked-icon">

                                                </label>
                                            </div>
                                        </td>
                                    </ng-container>
                            
                                    <tr mat-header-row *matHeaderRowDef="displayedColumnsAFDBStructure"></tr>
                                    <tr mat-row *matRowDef="let element; columns: displayedColumnsAFDBStructure;"
                                        class="example-element-row"
                                        [ngClass]="isExpandedAFDB(element) ? 'example-expanded-row' : ''" 
                                        
                                        (click)="expandRowAFDB(element)">
                                    </tr>
                                  
                                    <tr mat-row *matRowDef="let row; columns: ['expandedDetailAFDB']" class="example-detail-row"></tr>
                
                                    <ng-container *ngFor="let row of afdbStructureDataSource?.data">
                                        <ng-container *ngIf="isExpandedAFDB(row)" >
                                          <tr mat-row *matRowDef="let row; columns: ['expandedDetailAFDB']; when: isExpansionDetailRowAFDB" class="example-detail-row"></tr>
                                        </ng-container>
                                      </ng-container>
                                    </table>
                                
                                </div>
                               
                                <div  *ngIf="afdbZeroResultsMsg === ''" class="row threedViewer structureContainer mobile-error-msg">
                                    <p *ngIf="hasAFDBData === 1 && totalAFDBStructure == 0" class="noRecMsg">{{ errorAFDB }}</p>
                                </div>
                                
                                <div *ngIf="afdbZeroResultsMsg === ''" [ngClass]="errorAFDB ? 'nonMobile' : ''">
                                    <mat-paginator
                                    [length]="totalAFDBStructure"
                                    [pageSize]="recordsAFDB"
                                    [pageIndex]="currentAFDBPage"
                                    [pageSizeOptions]="[5, 10, 25, 50, 100]"
                                    aria-label="Select page"
                                    (page)="handlePageEvent($event,'afdb')"
                                  >
                                  </mat-paginator>
                                </div>
                                 
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                    <div class="superpose-wrapper">
                        <div class="superpose-align-1">
                            <div class="foldseek-pdb-entries-table-max">
                                <div class="try-2-parent">
                                        <div [ngClass]="isColorby ? 'plddtcolor-strap' : 'noplddt-strap'">
                                        </div>
                                        <div class="frame-parent3">
                                            <div class="af-q5vsl9-f1-parent">
                                                    <div class="seq-identity-entry">{{afdb_accession}}</div>
                                                    <div class="current-entry">Current entry</div>
                                            </div>
                                            <div class="checkboxes-parent">
                                                <div class="colour-by-plddt"> 
                                                    <div class="vf-form__item vf-form__item--checkbox">
                                                        <input
                                                        #benign
                                                        type="checkbox"
                                                        id="colorby"
                                                        value="colorby"
                                                        class="vf-form__checkbox"
                                                        [checked]="isColorby"
                                                        (change)=" (!isColorby); setsuperpose_color()"
                                                        [(ngModel)]="isColorby"
                                                        />
                                                        <label for="colorby" class="colorby-label vf-form__label">Colour by pLDDT
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                <div class="frame-wrapper">
                                    <div class="superpose-parent">
                                        <div class="load-spinner">
                                            <svg id="loading-spinner" style="display: none;" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z" class="spinner_aj0A" stroke="#3b6fb6" fill="#3B6FB6" />
                                            </svg>
                                        </div>
                                        <div id="superpose-viewer"></div>
                                    </div>
                                </div>
                            </div>
    
                            <div class="foldseek-pdb-entries-table-max">
                                <div class="aligned-in-3d-parent-export nonMobile">
                                    <div class="link-button">
                                        <div class="link-button-ex" *ngIf="superposeData.length"> 
                                            <a class="vf-button vf-button--primary vf-button--sm" style="color: #FFFFFF; width:98%;" (click)="exportSuperpose()">Download aligned coordinates (mmCIF) </a>
                                        </div>
                                        <div class="link-button-ex" *ngIf="superposeData.length === 0"> 
                                            <a class="vf-button vf-button--tertiary vf-button--sm" style="color: #FFFFFF; width:98%; cursor:not-allowed;">Download aligned coordinates (mmCIF) </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                        </div>
                        <div class="superpose-align-2">
                            <div class="foldseek-pdb-entries-table-max">
                                <div class="aligned-in-3d-parent">
                                    <div class="aligned-in-3d" style="display: flex; flex-direction: row; align-items:end;">Aligned in 3D
                                        <div class="label-tooltip">
                                            <svg class="que-svg" viewBox="0 0 24 24" style="margin-top: -4px;">
                                            <path fill="#373A36"
                                                    d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"/>
                                            </svg>
                                            <span class="label-header-tooltip-text" style="font-size: 14px; font-weight: 400; text-align: left; line-height: 22.4px;">
                                                Rotate and zoom structures in the 3D <br>viewer. Structures are aligned using alpha<br> carbon atoms as reference points. Once <br>aligned, RMSD values will appear in the<br> list below; lower values indicate greater <br>similarity between the two structures.
                                            </span>
                                        </div>
                                    </div>
                                    <div class="link-button">
                                    <div class="link-button-no" *ngIf="superposeData.length"><a (click) = "removeAllSuperposition()">Remove all</a></div>
                                    </div>
                                </div>
                            </div> 
                        
                            <div class="toggle-desc" *ngIf="!superposeData.length">
                                Toggle structures in both PDB and AFDB tables to view and align them with the entry structure in the 3D viewer. You can also download the aligned coordinates of the toggled structures and the entry structure.
                            </div>
    
                            <div class="aligned-structures">    
                                <div class="superposed-list"  [ngClass]="superposeData.length > 4 ? 'scrollable' : ''">
                                    <div class="superposed-1" *ngFor="let item of superposeData; let i = index; trackBy: trackByFn">
                                        <div class="superposed-1-child"  [ngClass]="!item.isShow ? 'blurit' : 'notblurit'" [ngStyle]="{'background-color': item.color}"></div>
                                        <div class="frame-container">
                                            <div class="k36-i-parent" [ngStyle]="{'opacity': !item.isShow ? '0.5' : '1'}">
                                                <div class="k36-i">
                                                    <a href="{{item.refLinkId}}" target="_blank">{{item.targetToShow}}</a>
                                                    <span class="rmsd">{{ item.rmsd }}Å</span></div>
                                                <div *ngIf="item.desc.length <= 30" class="protein-ecdysoneless-homolog nonMobile">{{ item.desc }} </div>
    
                                                <div class="label-tooltip" *ngIf="item.desc.length > 30">
                                                    <p class="descEllipse protein-ecdysoneless-homolog">{{item.desc.slice(0,30)}}... </p>
                                                    
                                                    <span class="label-tooltip-text" style="width: 155px;
                                                    word-break: break-all;">{{item.desc}}</span>
                                                </div>
                                                
    
                                            </div>
                                            
                                            <div class="buttons">
                                                <div id="{{item.accession}}-visibility"></div>
                                                <img  *ngIf="item.isShow" id="{{item.accession}}-closeeye" style="display: block;" alt="" src="assets/img/eye.svg" class="open-eye"  (click) ="toggleSupeposeVisibility($event, item)" />
    
                                                <img  *ngIf="!item.isShow" id="{{item.accession}}-closeeye" alt="" src="assets/img/closeeye.svg" class="closed-eye"  (click) ="toggleSupeposeVisibility($event, item)"  />
                                                    
                                                <div class="close">
                                                    <div class="cross-close">
                                                        <svg (click)="removeSuperpose(item)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0_2268_35092)">
                                                            <path d="M13.9794 12.4958C13.9142 12.4308 13.8625 12.3535 13.8272 12.2685C13.7919 12.1834 13.7738 12.0922 13.7738 12.0002C13.7738 11.9081 13.7919 11.8169 13.8272 11.7319C13.8625 11.6468 13.9142 11.5696 13.9794 11.5046L18.6918 6.79218C18.8773 6.59313 18.9783 6.32987 18.9735 6.05784C18.9687 5.78582 18.8585 5.52628 18.6661 5.33391C18.4737 5.14153 18.2142 5.03133 17.9422 5.02653C17.6701 5.02173 17.4069 5.1227 17.2078 5.30818L12.4954 10.0206C12.4304 10.0858 12.3532 10.1375 12.2681 10.1728C12.1831 10.2081 12.0919 10.2262 11.9998 10.2262C11.9077 10.2262 11.8166 10.2081 11.7315 10.1728C11.6465 10.1375 11.5692 10.0858 11.5042 10.0206L6.79182 5.30818C6.59278 5.1227 6.32951 5.02173 6.05749 5.02653C5.78547 5.03133 5.52593 5.14153 5.33355 5.33391C5.14118 5.52628 5.03098 5.78582 5.02618 6.05784C5.02138 6.32987 5.12235 6.59313 5.30782 6.79218L10.0202 11.5046C10.0854 11.5696 10.1371 11.6468 10.1724 11.7319C10.2077 11.8169 10.2259 11.9081 10.2259 12.0002C10.2259 12.0922 10.2077 12.1834 10.1724 12.2685C10.1371 12.3535 10.0854 12.4308 10.0202 12.4958L5.30782 17.2082C5.20466 17.3043 5.12192 17.4202 5.06453 17.549C5.00714 17.6778 4.97628 17.8169 4.9738 17.9578C4.97131 18.0988 4.99724 18.2389 5.05005 18.3696C5.10286 18.5004 5.18146 18.6191 5.28117 18.7188C5.38088 18.8185 5.49964 18.8971 5.63039 18.9499C5.76113 19.0028 5.90117 19.0287 6.04216 19.0262C6.18314 19.0237 6.32218 18.9929 6.45098 18.9355C6.57978 18.8781 6.6957 18.7953 6.79182 18.6922L11.5042 13.9798C11.5692 13.9146 11.6465 13.8629 11.7315 13.8276C11.8166 13.7923 11.9077 13.7741 11.9998 13.7741C12.0919 13.7741 12.1831 13.7923 12.2681 13.8276C12.3532 13.8629 12.4304 13.9146 12.4954 13.9798L17.2078 18.6922C17.4069 18.8776 17.6701 18.9786 17.9422 18.9738C18.2142 18.969 18.4737 18.8588 18.6661 18.6664C18.8585 18.4741 18.9687 18.2145 18.9735 17.9425C18.9783 17.6705 18.8773 17.4072 18.6918 17.2082L13.9794 12.4958Z" fill="#3B6FB6"/>
                                                            </g>
                                                            <defs>
                                                            <clipPath id="clip0_2268_35092">
                                                            <rect width="14" height="14" fill="white" transform="translate(5 5)"/>
                                                            </clipPath>
                                                            </defs>
                                                        </svg>
                                                            
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>    
                            </div>
                       </div>
                       

                    </div>
                </div>
            </div>
        </div>
    </div>  
   
    <div class="columns empty-stage-box"  *ngIf="isStructureCach === false && hasPDBData == -1 && !isStructureFail">
        <div class="frame-parent">
            <div class="be-the-first-to-explore-parent">
                <div class="be-the-first">Explore similar structures</div>
                <div class="it-appears-that paeDescription">Structure search is powered by Foldseek, a tool for fast protein structure comparison. <br>
                    Start a structural similarity search to discover similar proteins.</div>
            </div>
            <div class="button">
                <a  class="vf-button vf-button--secondary vf-button--sm" style="color: #3B6FB6"
                    (click)="startStructureSearch()">
                    Find similar structures
                </a>
               
            </div>
        </div>
    </div>  
   
    <div class="columns empty-stage-box"  *ngIf="isStructureCach === true && !isStructureRunning && (hasPDBData == -1 && isPDBLoading === false) && (hasAFDBData == -1 && isAFDBLoading === false) && !isStructureFail">
        <div class="frame-parent">
            <div class="be-the-first-to-explore-parent">
                <div class="be-the-first">No similar structures found</div>
                <div class="it-appears-that">It seems there are no similar structures found in PDB and AFDB.</div>
            </div>
        </div>
    </div>  
    <div class="columns empty-stage-box" *ngIf="isStructureRunning && hasStructureData == -1 && !isStructureFail && hasAFDBData == -1">
        <div class="frame-parent">
            <div class="be-the-first-to-explore-parent">
                <div class="be-the-first">Structure similarity search in progress</div>
                <div class="it-appears-that">The Foldseek structure similarity search is currently in progress. <br/>Please stand by, the search usually takes a few minutes to complete.</div>
            </div>
            <div>
                <svg style="display: block;" width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z" class="spinner_aj0A" stroke="#707372" fill="#707372" />
                </svg>
            </div>
        </div>
    </div>  
    <div class="columns empty-stage-box" *ngIf="isStructureFail && (hasPDBData == -1 && isPDBLoading === false) && (hasAFDBData == -1 && isAFDBLoading === false)">
        <div class="frame-parent">
            <div class="be-the-first-to-explore-parent">
                <div class="be-the-first">Search error</div>
                <div class="it-appears-that">An error occurred while processing your Foldseek structure similarity search. Please try again later or contact us at afdbhelp&#64;ebi.ac.uk if the error persists.</div>
            </div>
            <div class="button">
                <a  class="vf-button vf-button--secondary vf-button--sm" style="color: #3B6FB6"
                    (click)="startStructureSearch()"
                    target="_blank">
                    Find similar structures
                </a>
            </div>
        </div>
    </div> 
</div>